export const tccServices = [
    {id: '1', display: 'Program Support'},
    {id: '2', display: 'Invoices and Billing'},
    {id: '3', display: 'Contracts'},
    {id: '4', display: 'Data and Reporting'},
    {id: '5', display: 'Imaging Quotes and Orders'},
    {id: '6', display: 'Probe Orders/Repairs'},
    {id: '7', display: 'Parts ID'},
    {id: '8', display: 'DI Services'},
    {id: '9', display: 'Escalation Issues'},
];

export const tccCustCareServices = [
    {id: '1', display: 'Order Inquiries', img: '/images/TeamContactCenter/icn-order-inquiries.svg'},
    {id: '2', display: 'Returns', img: '/images/TeamContactCenter/icn-returns.svg'},
    {id: '3', display: 'Part Inquiries', img: '/images/TeamContactCenter/icn-part.svg'},
    {id: '4', display: 'Repairs & Paperwork', img: '/images/TeamContactCenter/icn-paperwork.svg'},
    {id: '5', display: 'General Questions', img: '/images/TeamContactCenter/icn-part-inquiries.svg'},
];

export const titleMaxLength = 100;
export const subheaderMaxLength = 500;
export const phoneMaxLength = 12;
